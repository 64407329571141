import React from "react";
import { Typography, Card, Box, Divider } from "@mui/material";

const ITConsultingArticle = () => {
  return (
    <Card elevation={3} style={{ padding: "1rem", marginBottom: "1rem" }}>
      <Typography variant="h5" mt={2} mb={2}>
        IT Consulting Services
      </Typography>

      {/* Proven Strategies for Business Transformation */}
      <Typography variant="h6" mt={2} mb={1}>
        Proven Strategies for Business Transformation
      </Typography>
      <Typography variant="body1" paragraph>
        At Electronetix Ltd, our IT consulting services are designed to
        transform your business into a digital powerhouse. With an in-depth
        understanding of technology and its impact on business processes, we
        offer actionable insights and strategic guidance that drive measurable
        results. From IT infrastructure optimization to software selection and
        cybersecurity, our team of seasoned consultants helps you make informed
        decisions that align with your business goals.
      </Typography>

      {/* Custom Solutions for Complex Challenges */}
      <Typography variant="h6" mt={2} mb={1}>
        Custom Solutions for Complex Challenges
      </Typography>
      <Typography variant="body1" paragraph>
        We know that every business is unique, facing its own set of challenges
        and opportunities. That's why we tailor our consulting services to meet
        your specific needs. Whether you are looking to migrate to the cloud,
        improve operational efficiency, or develop a robust security posture,
        Electronetix Ltd offers custom solutions that solve your most complex IT
        challenges.
      </Typography>

      {/* Data-Driven Approach */}
      <Typography variant="h6" mt={2} mb={1}>
        Data-Driven Approach
      </Typography>
      <Typography variant="body1" paragraph>
        Our IT consulting process is deeply rooted in data analytics and
        performance metrics. We employ a range of tools to assess your current
        IT environment, identifying areas for improvement and potential risks.
        This data-driven approach ensures that the strategies we recommend are
        not just theoretically sound, but empirically validated, guaranteeing a
        higher ROI and long-term success for your business.
      </Typography>

      {/* A Long-Term Partnership */}
      <Typography variant="h6" mt={2} mb={1}>
        A Long-Term Partnership
      </Typography>
      <Typography variant="body1" paragraph>
        Beyond the initial consultancy and project execution, Electronetix Ltd
        is committed to a long-term partnership that ensures sustained growth
        and continuous improvement. Our post-implementation support includes
        regular performance reviews, updates, and ongoing consultancy to keep
        your IT infrastructure agile and efficient.
      </Typography>

      <Typography variant="body1" paragraph>
        By partnering with Electronetix Ltd for your IT consulting needs, you’re
        ensuring a future-proof strategy that maximizes efficiency, security,
        and business growth.
      </Typography>

      <Divider />

      <Typography variant="body2" align="right" mt={2}>
        For more details, please contact us.
      </Typography>
    </Card>
  );
};

export default ITConsultingArticle;
