import {
  AppBar,
  Box,
  Container,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ParticleAnimation from "../components/ParticleAnimation";

const BasePage = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      {/* NAVBAR HERE */}
      <AppBar position="sticky" variant="elevation">
        <Toolbar variant="dense">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Electronetix Ltd
          </Typography>
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            <Button color="inherit">Home</Button>
          </Link>
          <Link
            to="/services"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Button color="inherit">Services</Button>
          </Link>
          <Link
            to="/contact"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Button color="inherit">Contact</Button>
          </Link>
        </Toolbar>
      </AppBar>

      {/* Main content */}
      <Box flex="1" mb={6}>
        <ParticleAnimation />
        {children}
      </Box>

      {/* Footer */}
      <AppBar position="static" color="primary">
        <Container>
          <Box py={1}>
            <Typography variant="body2" color="inherit" align="center">
              © 2023 Electronetix Ltd. All rights reserved.
            </Typography>
          </Box>
        </Container>
      </AppBar>
    </Box>
  );
};

export default BasePage;
