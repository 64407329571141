import ContactUsPage from "./pages/ContactUsPage";
import HomePage from "./pages/HomePage";
import ServicesPage from "./pages/ServicesPage";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme from "./components/theme";

//

//

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
