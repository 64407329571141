import React from "react";
import { Typography, Card, Divider } from "@mui/material";

const ElectronicManufacturingArticle = () => {
  return (
    <Card elevation={3} style={{ padding: "1rem", marginBottom: "1rem" }}>
      <Typography variant="h5" mt={2} mb={2}>
        Electronic Manufacturing Consultancy
      </Typography>

      {/* Unmatched Expertise */}
      <Typography variant="h6" mt={2} mb={1}>
        Unmatched Expertise
      </Typography>
      <Typography variant="body1" paragraph>
        Electronetix Ltd has been at the forefront of the electronics
        manufacturing industry for years, providing tailor-made consultancy
        solutions that meet the unique needs of our clients. Our unmatched
        expertise in the field allows us to provide guidance on highly complex
        projects, ensuring that your electronic products are not just
        market-ready but also competitive. With a focus on innovation,
        efficiency, and scalable production, we are well-equipped to handle the
        entire lifecycle of your electronic products—from the initial concept to
        strategic consultancy.
      </Typography>

      {/* State-of-the-Art Technology */}
      <Typography variant="h6" mt={2} mb={1}>
        State-of-the-Art Technology
      </Typography>
      <Typography variant="body1" paragraph>
        We leverage state-of-the-art technology and manufacturing knowledge to
        deliver high-quality consultancy services that adhere to industry
        standards. Whether it's providing guidance on surface-mount technology
        or mastering the art of multi-layer PCB design, our consultancy
        capabilities are versatile and scalable. Our highly-qualified experts
        make use of advanced tools and AI-driven analysis to guarantee the kind
        of precision and reliability that our clients have come to expect from
        us.
      </Typography>

      {/* Environmentally Conscious */}
      <Typography variant="h6" mt={2} mb={1}>
        Environmentally Conscious
      </Typography>
      <Typography variant="body1" paragraph>
        Electronetix Ltd is deeply committed to environmental stewardship. Our
        consultancy practices are designed to be eco-friendly, employing
        sustainability-focused techniques and responsible materials whenever
        possible. We abide by all local and international environmental
        regulations, and are continuously seeking ways to reduce our carbon
        footprint. This not only makes us a responsible business but also allows
        our clients to benefit from cost-effective, green consultancy solutions.
      </Typography>

      {/* End-to-End Solutions */}
      <Typography variant="h6" mt={2} mb={1}>
        End-to-End Solutions
      </Typography>
      <Typography variant="body1" paragraph>
        Our services don't just end at consultancy. Electronetix Ltd offers a
        comprehensive range of post-consultancy services, including product
        testing, strategic planning, and project management support. Our aim is
        to provide a seamless, end-to-end consultancy service that alleviates
        the pressures and complexities often associated with electronic
        manufacturing. Our dedicated project managers ensure timely deliveries
        and maintain open communication lines to keep you updated every step of
        the way.
      </Typography>

      <Typography variant="body1" paragraph>
        By choosing Electronetix Ltd for your electronic manufacturing
        consultancy needs, you're partnering with a service provider that is
        dedicated to delivering quality, efficiency, and sustainable solutions.
      </Typography>

      <Divider />

      <Typography variant="body2" align="right" mt={2}>
        For more details, please contact us.
      </Typography>
    </Card>
  );
};

export default ElectronicManufacturingArticle;
