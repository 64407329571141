import React, { useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  Grid,
  CssBaseline,
  AppBar,
} from "@mui/material";
import CloudIcon from "@mui/icons-material/Cloud";
import SecurityIcon from "@mui/icons-material/Security";
import StorageIcon from "@mui/icons-material/Storage";
import BasePage from "./BasePage";
import TerminalIcon from "@mui/icons-material/Terminal";
import FactoryIcon from "@mui/icons-material/Factory";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import CardActions from "@mui/material/CardActions";
import ParticleAnimation from "../components/ParticleAnimation";

const HomePage = () => {
  return (
    <BasePage>
      <CssBaseline />
      <Box
        sx={{
          backgroundImage: "url('assets/banner.avif')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 3,
        }}
      >
        <Card
          sx={{
            width: { xs: "90%", sm: "70%", md: "70%" },
            textAlign: "center",
            opacity: "97%",
            backgroundColor: "#875053",
            backgroundColor: "#987D7C",
          }}
        >
          <CardContent>
            <Typography variant="h2" component="div">
              <Box
                fontSize={{
                  xs: "h6.fontSize",
                  sm: "h5.fontSize",
                  md: "h4.fontSize",
                }}
              >
                Your Partner in IT Consulting and Electronics Manufacturing
                Solutions
              </Box>
            </Typography>
            <Box mb={2} />
            <Typography variant="body1">
              <Box fontSize={{ xs: "body2.fontSize", sm: "body1.fontSize" }}>
                Welcome to Electronetix Ltd, your trusted partner in IT
                Consulting and Electronics Manufacturing Solutions. With a
                global reach and a commitment to innovation, we offer end-to-end
                services that streamline your IT infrastructure and optimize
                your electronics manufacturing processes. Our dedicated team of
                experts specialize in consulting, strategic planning, and
                providing import/export solutions for electronic components,
                ensuring you stay ahead in an ever-evolving industry. Partner
                with us to transform your operational efficiency, reduce costs,
                and achieve unparalleled excellence.
              </Box>
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box mb={6} />
      {/* Services section */}
      <Container maxWidth="xl">
        <Box my={3}>
          <Typography variant="h4">Services</Typography>
          <Box mb={5}></Box>
          <Grid container spacing={4} sx={{ justifyContent: "center" }}>
            {[
              {
                icon: <TerminalIcon fontSize="large" />,
                title: "IT Consulting",
                description:
                  "                Electronetix Ltd's IT Consulting services provide tailored,\n                data-driven strategies designed to transform your business\n                operations and drive measurable results. From custom solutions\n                for IT infrastructure optimization to cybersecurity and cloud\n                migration, we offer a holistic, long-term partnership for your\n                evolving needs. Our focus on actionable insights and\n                post-implementation support ensures a high ROI and a\n                future-proof IT strategy.",
                // (truncated for brevity)
              },
              {
                icon: <CloudIcon fontSize="large" />,
                title: "Cloud Solutions",
                description:
                  "Electronetix Ltd offers robust and secure Cloud Solutions designed to provide scalability, efficiency, and optimal resource utilization. Our services cover the full cloud lifecycle, from initial strategy and migration to ongoing management, with a strong focus on security and cost-effectiveness. Partnering with us ensures a seamless transition to the cloud, enabling your business to thrive in today's digital ecosystem.",
                // (truncated for brevity)
              },
              {
                icon: <SettingsInputComponentIcon fontSize="large" />,
                title: "Electronic Component Import/Export Consultancy",
                description:
                  "Electronetix Ltd’s Electronic Component Import/Export Consultancy provides a comprehensive, end-to-end solution for your supply chain needs. Leveraging a global network and focusing on quality and compliance, we optimize costs and ensure timely delivery of components. Our full-spectrum logistics support covers everything from customs clearance to warehousing, offering you a hassle-free and efficient way to manage component supplies globally.\n",
                // (truncated for brevity)
              },
              {
                icon: <FactoryIcon fontSize="large" />,
                title: "Electronic Manufacturing Consultancy",
                description:
                  "Electronetix Ltd offers comprehensive Electronic Manufacturing Consultancy,\nleveraging state-of-the-art technology and industry expertise to provide\nguidance on complex projects from concept to strategic consultancy. We're\ncommitted to both quality and environmental sustainability, employing\nadvanced automation and eco-friendly practices to deliver reliable and\ncost-effective consultancy services. Our end-to-end services include\npost-consultancy support such as product testing, strategic planning, and\nproject management, making us a one-stop solution for all your electronic\nmanufacturing consultancy needs.\n",
                // (truncated for brevity)
              },
            ].map((service, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Card
                  sx={{
                    height: "100%",
                  }}
                >
                  <CardContent>
                    {service.icon}
                    <Typography variant="h6" fontWeight="bold">
                      {service.title}
                    </Typography>
                    <Typography>{service.description}</Typography>
                  </CardContent>
                  <CardActions>
                    <Link to="/services" style={{ textDecoration: "none" }}>
                      <Button size="small" color="primary">
                        Learn More
                      </Button>
                    </Link>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </BasePage>
  );
};

export default HomePage;
