import React from "react";
import { Container, Box, Typography, Paper, CssBaseline } from "@mui/material";
import BasePage from "./BasePage";

const ContactUsPage = () => {
  return (
    <BasePage>
      <CssBaseline />
      <Container>
        <Box my={5}>
          <Typography variant="h4" align="center" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph align="center">
            We'd love to hear from you. Here's how you can reach us.
          </Typography>

          <Paper elevation={3}>
            <Box p={3}>
              <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                  Email Address:
                </Typography>
                <Typography variant="body1">
                  angelika.siwek@electronetixltd.com
                </Typography>
              </Box>

              <Typography variant="h6" gutterBottom>
                Post Address:
              </Typography>
              <Typography variant="body1">
                Electronetix Ltd,
                <br />
                47 Granes End,
                <br />
                Great Linford,
                <br />
                Milton Keynes,
                <br />
                MK14 5DY,
                <br />
                United Kingdom
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Container>
    </BasePage>
  );
};

export default ContactUsPage;
