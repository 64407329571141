import React from "react";
import { Typography, Divider, Card } from "@mui/material";

const CloudSolutionsArticle = () => {
  return (
    <Card elevation={3} style={{ padding: "1rem", marginBottom: "1rem" }}>
      <Typography variant="h5" mt={2} mb={2}>
        Cloud Solutions at Electronetix Ltd
      </Typography>

      {/* Agile and Scalable Cloud Ecosystems */}
      <Typography variant="h6" mt={2} mb={1}>
        Agile and Scalable Cloud Ecosystems
      </Typography>
      <Typography variant="body1" paragraph>
        In today's rapidly evolving digital landscape, cloud solutions are a
        necessity for achieving business agility and scalability. At
        Electronetix Ltd, we specialize in providing robust, flexible cloud
        environments that can easily adapt to your business's growing needs.
        With expertise in public, private, and hybrid cloud configurations, we
        offer comprehensive solutions that optimize your IT resources and
        enhance operational efficiency.
      </Typography>

      {/* Security and Compliance */}
      <Typography variant="h6" mt={2} mb={1}>
        Security and Compliance
      </Typography>
      <Typography variant="body1" paragraph>
        Security is paramount in a cloud-based infrastructure. That's why our
        cloud solutions come with multiple layers of security features,
        including data encryption, access control, and regular vulnerability
        assessments. In addition to robust security protocols, we are committed
        to meeting industry-specific compliance standards, offering you peace of
        mind as you move your operations to the cloud.
      </Typography>

      {/* Cost-Effective Solutions */}
      <Typography variant="h6" mt={2} mb={1}>
        Cost-Effective Solutions
      </Typography>
      <Typography variant="body1" paragraph>
        The cost savings associated with cloud computing are significant, but
        only if managed correctly. At Electronetix Ltd, we focus on delivering
        cost-effective solutions that don't compromise on performance or
        reliability. Our cloud services are designed to scale with your
        business, allowing you to pay only for the resources you use, thus
        optimizing your investment.
      </Typography>

      {/* End-to-End Support */}
      <Typography variant="h6" mt={2} mb={1}>
        End-to-End Support
      </Typography>
      <Typography variant="body1" paragraph>
        Choosing Electronetix Ltd for your cloud solutions means gaining a
        partner that offers end-to-end support. From the initial assessment and
        migration strategy to ongoing management and troubleshooting, we provide
        a full spectrum of services that ensure a smooth transition and
        continuous optimization of your cloud infrastructure.
      </Typography>

      {/* Conclusion */}
      <Typography variant="h6" mt={2} mb={1}>
        Conclusion
      </Typography>
      <Typography variant="body1" paragraph>
        By opting for Electronetix Ltd's Cloud Solutions, you'll benefit from an
        agile, secure, and cost-effective cloud environment, backed by our
        commitment to excellence and end-to-end support.
      </Typography>

      <Divider />

      <Typography variant="body2" align="right" mt={2}>
        For more details, please contact us.
      </Typography>
    </Card>
  );
};

export default CloudSolutionsArticle;
