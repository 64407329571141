import React from "react";
import { Container, Box, Typography, Paper, CssBaseline } from "@mui/material";
import BasePage from "./BasePage";
import ImportExportArticle from "../components/ImportExportArticle";
import ElectronicManufacturingArticle from "../components/ElectronicManufacturingArticle";
import ITConsultingArticle from "../components/ITConsultingArticle";
import CloudSolutionsArticle from "../components/CloudSolutionsArticle";

const ServicesPage = () => {
  const marginBottom = 6;
  return (
    <BasePage>
      <CssBaseline />
      <Container>
        <Box my={5}>
          <Typography variant="h4" align="center" gutterBottom>
            Our Services
          </Typography>
          <Box mb={marginBottom} />
          <ITConsultingArticle />
          <Box mb={marginBottom} />
          <CloudSolutionsArticle />
          <Box mb={marginBottom} />
          <ElectronicManufacturingArticle />
          <Box mb={marginBottom} />
          <ImportExportArticle />
          <Box mb={marginBottom} />
        </Box>
      </Container>
    </BasePage>
  );
};

export default ServicesPage;
