import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    type: "dark",
    background: {
      default: "#121212",
      paper: "#3a9efd",
    },
    text: {
      primary: "#ffffff",
      secondary: "#bbbbbb",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#2C2C54",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: "#2C2C54",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#937776",
          padding: 5,
          paddingRight: 10,
          paddingLeft: 10,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#937776",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#ddd",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#009688", // Light blue for visibility
          textDecoration: "underline",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#ddd",
          textTransform: "none",
        },
        containedPrimary: {
          backgroundColor: "#009688", // Some dark color for visibility
          color: "white",
        },
      },
    },
  },
});

export default theme;
