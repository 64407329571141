import React from "react";
import { Typography, Card, Divider } from "@mui/material";

const ImportExportArticle = () => {
  return (
    <Card elevation={3} style={{ padding: "1rem", marginBottom: "1rem" }}>
      <Typography variant="h5" mt={2} mb={2}>
        Electronic Component Import/Export Consultancy
      </Typography>

      {/* Global Network, Local Solutions */}
      <Typography variant="h6" mt={2} mb={1}>
        Global Network, Local Solutions
      </Typography>
      <Typography variant="body1" paragraph>
        In the interconnected world of electronics and IT, the ability to
        efficiently manage component import and export can be a game-changer for
        your business. Electronetix Ltd leverages a global network of suppliers
        and logistics partners to offer streamlined component import/export
        consultancy services. Our reach extends across multiple countries, yet
        our solutions are tailored to local market needs, ensuring a smooth and
        timely supply chain.
      </Typography>

      {/* Quality and Compliance */}
      <Typography variant="h6" mt={2} mb={1}>
        Quality and Compliance
      </Typography>
      <Typography variant="body1" paragraph>
        We understand that quality assurance and regulatory compliance are
        critical in the component import/export consultancy process. That's why
        we commit to stringent quality checks and are aligned with international
        standards. From conducting thorough inspections to managing custom
        clearances, we handle every aspect meticulously to ensure that the
        components meet your specifications and compliance needs.
      </Typography>

      {/* Cost Optimization */}
      <Typography variant="h6" mt={2} mb={1}>
        Cost Optimization
      </Typography>
      <Typography variant="body1" paragraph>
        One of the main concerns with global import/export is cost. At
        Electronetix Ltd, we work tirelessly to optimize the costs associated
        with customs, shipping, and storage, passing the savings onto you. Our
        advanced tracking systems also provide real-time updates on your
        shipments, allowing for better planning and inventory management.
      </Typography>

      {/* End-to-End Logistics Support */}
      <Typography variant="h6" mt={2} mb={1}>
        End-to-End Logistics Support
      </Typography>
      <Typography variant="body1" paragraph>
        Our service doesn't end when the components reach your doorstep. We
        offer end-to-end logistics support, taking care of warehousing,
        distribution, and even reverse logistics for defective or outdated
        components. Our aim is to provide a hassle-free, complete solution for
        all your component import/export consultancy needs.
      </Typography>
      <Typography variant="body1" paragraph>
        By engaging with Electronetix Ltd for your component import/export
        consultancy services, you're securing a reliable, efficient, and
        compliant solution that enhances your supply chain and contributes to
        your business's success.
      </Typography>

      <Divider />

      <Typography variant="body2" align="right" mt={2}>
        For more details, please contact us.
      </Typography>
    </Card>
  );
};

export default ImportExportArticle;
